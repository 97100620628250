import {
	CONNECTED,
	CONNECTING,
	CONNECTION_FAILED,
	DISCONNECTED,
	DISCONNECTING,
	INITIALIZATION_FAILED,
	INITIALIZING,
	RECONNECTING,
	desktopNotification,
	playAudio,
	toggleState
} from "./comms";

import { Client as ConversationsClient } from "@twilio/conversations";
import axios from "axios";
import { isEmpty } from "lodash";
import router from "../router";
import store from "../store";
import i18n from "../i18n";
import { getAuthType } from "./mail";
import { PHONE_CALL_STATUS } from "@/common/const";

export const TWILIO_CONVERSATIONS_MAX = 1000;

let conversationsClient = null;

let lastVoiceEvents = {};

export function getConversation(sid) {
	return conversationsClient?.conversations?.conversations.get(sid);
}

export function getConversationsMap() {
	return conversationsClient?.conversations?.conversations;
}

let conversationEvents = false;

const useConversation = () => {
	const authenticate = async (userGroupId) => {
		console.debug(`Authenticating Twilio client for group [${userGroupId}]`);

		const { data: { migrating, jwt, group, calls } } = await axios.post("conversations/tokens", {
			userGroup: userGroupId
		});

		if (migrating) {
			setTimeout(async () => authenticate(userGroupId), 3000);
		} else {
			conversationTokenAquired(jwt, group);
			store.dispatch("queue/setQueue", calls);
		}
	};

	const refreshToken = async () => {
		const { data: token } = await axios.post("conversations/refreshTokens");

		conversationsClient.updateToken(token.jwt);
	};

	const onConversationUpdated = (updatePayload) => {
		const { updateReasons, conversation } = updatePayload;

		store.commit("conversation/updateConversation", { conversation, updateReasons });

		const hasNotification = conversation.attributes.hasNotification;

	 let unreadCountUpdated = false;

		if (updateReasons.includes("attributes")) {
			// Update queue in real time
			if (conversation?.attributes?.voice?.time &&
				lastVoiceEvents?.[conversation.sid] !== conversation.attributes.voice.time) {
				lastVoiceEvents[conversation.sid] = conversation.attributes.voice.time;

				const organization = store.getters["clinic/clinicData"];

				if (conversation.attributes.voice.status === "queued" && organization.options.voiceQueue.notification) {
					desktopNotification();
				}
				store.dispatch("queue/updateQueue", conversation.attributes.voice);
				return;
			}

			if (conversation.attributes.hasNotification &&
				!conversation.attributes.hasIncompleteFollowup) {
				playAudio();
				desktopNotification();
			}

			const state = conversation.channelState.state.current;

			if (state === "active") {
				store.commit("conversation/updateHasNotificationSids", { conversation, hasNotification });
				unreadCountUpdated = true;
			} else {
				store.commit("conversation/updateHasNotificationInactiveSids", { conversation, hasNotification });
				unreadCountUpdated = true;
			}
		}

		if (updateReasons.includes("state") && hasNotification && !unreadCountUpdated) {
			store.commit("conversation/toggleUnreadNotificationState", { conversation, archive: conversation.channelState.state.current === "inactive" });
		}
	};

	const onConversationLeft = (conversation) => {
		console.debug("onConversationLeft ", conversation.sid);
		store.commit("conversation/removeConversation", conversation);
	};

	const onConversationJoined = async (conversation) => {
		console.debug("onConversationJoined ", conversation.sid, " attributes ", conversation.attributes);

		store.commit("conversation/addConversation", conversation);

		if (conversation?.attributes?.voice?.status &&
			conversation.attributes.voice.status !== "completed") {
			lastVoiceEvents[conversation.sid] = conversation.attributes.voice.time;
			store.dispatch("queue/updateQueue", conversation.attributes.voice);
			return;
		}

		if (conversation.lastMessage?.index >= 0) {
			playAudio();
			desktopNotification();
		}
	};

	const messageAdded = (message) => {
		if (message.attributes.isFlowMessage) {
			console.debug("messageAdded: Flow message");
			return;
		}

		const isCallCompleted = message.state.attributes.status === PHONE_CALL_STATUS.COMPLETED;
		const isPhoneCallMessage =
				message.state?.attributes?.isCall && message.state?.attributes?.direction === "inbound";
		const isEmailMessage = message.state.attributes?.type === "email" && message.state.attributes?.fromPatient;
		const isTextMessage = message.state?.type === "text" && isEmpty(message.state.attributes);

		if (
			message.state.author !== store.getters["conversation/currentParticipant"].identity &&
			message.conversation.sid === store.getters["conversation/singleConversation"]?.sid
		) {
			if (isEmailMessage || (isPhoneCallMessage && !isCallCompleted) || isTextMessage ) {
				playAudio();
				desktopNotification();
			}
		}

		if (
			router.currentRoute.name == "conversations" &&
			message.conversation.sid === store.getters["conversation/singleConversation"]?.sid
		) {
			store.commit("conversation/addSingleMessage", message);
			store.commit("conversation/setNewMessage", true);
		} else {
			if (message.state.author === store.getters["conversation/currentParticipant"].identity) {
				console.warn("messageAdded: Author is same as participant");
				return;
			}

			if (isEmailMessage || (isPhoneCallMessage && !isCallCompleted) || isTextMessage ) {
				playAudio();
				desktopNotification();
			}

		}

		console.debug("messageAdded");
	};

	const loadExistingConversations = async () => {
		if (store.getters["conversation/loading"]) {
			console.debug("loadAllConversations is already running");
			return;
		}

		if (conversationsClient.connectionState !== CONNECTED) {
			throw new Error("Conversations are not connected yet");
		}

		try {
			// Make sure no conversations events are set
			removeConversationEvents();

			store.commit("conversation/setLoadingAllConversations", true);

			const start = new Date().getTime();

			console.debug("loadAllConversations ...");

			let actives = [];

			let inactives = [];

			let notifications = new Set();

			let notificationInactive = new Set();

			// Load first page
			let pager = await conversationsClient.getSubscribedConversations({
				limit: TWILIO_CONVERSATIONS_MAX
			});

			pager.items.map(c=> {
				if (c.attributes?.voice?.time) {
					lastVoiceEvents[c.sid] = c.attributes.voice.time;
				}

				if (c.state.current === "active") {
					if (c.channelState.attributes.hasNotification) {
						notifications.add(c.sid);
					}
					actives.push(c.sid);
				} else {
					if (c.channelState.attributes.hasNotification) {
						notificationInactive.add(c.sid);
					}
					inactives.push(c.sid);
				}

			});

			const fetchNextPage = async (pager) => {
				// Loop for each 100 convo per page
				if (!pager.hasNextPage) {
					store.commit("conversation/setAllConversation", { actives, inactives, notifications, notificationInactive });
					console.debug(`loadAllConversations took ${new Date().getTime() - start} ms`);

					toggleState(CONNECTED);
					loadConversationEvents();
					return;
				}

				pager = await pager.nextPage();

				pager.items.map(c=> {
					if (c.attributes?.voice?.time) {
						lastVoiceEvents[c.sid] = c.attributes.voice.time;
					}

					if (c.state.current === "active") {
						if (c.channelState.attributes.hasNotification) {
							notifications.add(c.sid);
						}
						actives.push(c.sid);
					} else {
						inactives.push(c.sid);
					}
				});

				fetchNextPage(pager);
			};

			// Load subsequent pages and recurse
			fetchNextPage(pager);
		} catch (err) {
			console.error(`Exceptions occured in loadExistingConversations. ${err}`);
			throw err;
		} finally {
			store.commit("conversation/setLoadingAllConversations", false);
		}
	};

	let lastConnectionErrorTime = 0;

	const connectionError = async (error) => {
		console.error("connectionError:", error);

		const now = Date.now();

		if (now - lastConnectionErrorTime > 10000) {
			lastConnectionErrorTime = now;

			store.commit("alerts/add", {
				type: "error",
				message: i18n.t("error.connection-unstable"),
				timeout: true
			});
		}

		if (!error.terminal) return;
		toggleState(CONNECTION_FAILED);
	};

	const connectionStateChanged = async (connectionState) => {
		console.debug("connectionState:", connectionState);

		switch (connectionState) {
			case CONNECTING:
				toggleState(CONNECTING);
				break;
			case CONNECTED:
				loadExistingConversations().catch(err => {
					console.error(`Error while connecting conversation ${err}`);
					toggleState(INITIALIZATION_FAILED);
				});
				break;
			case DISCONNECTING:
			// Prevents "Connexion interompue" when switching groups or updating patients
			case RECONNECTING:
				toggleState(RECONNECTING);
				break;
			case DISCONNECTED:
				if (store.getters["conversation/connectionState"] !== RECONNECTING) {
					toggleState(DISCONNECTED);
				}
				break;
		}
	};

	const removeConversationEvents = () => {
		if (!conversationEvents) {
			return;
		}

		try {
			conversationsClient.off("tokenAboutToExpire", refreshToken);
			conversationsClient.off("tokenExpired", refreshToken);
			conversationsClient.off("conversationJoined", onConversationJoined);
			conversationsClient.off("conversationUpdated", onConversationUpdated);
			conversationsClient.off("conversationLeft", onConversationLeft);
			conversationsClient.off("messageAdded", messageAdded);
			conversationEvents = false;
		} catch (err) {
			console.error("Error while removing conversation events ", err);
			throw err;
		}
	};

	const loadConversationEvents = () => {
		if (conversationEvents) {
			return;
		}

		try {
			conversationsClient.on("conversationJoined", onConversationJoined);
			conversationsClient.on("conversationUpdated", onConversationUpdated);
			conversationsClient.on("conversationLeft", onConversationLeft);

			conversationsClient.on("messageAdded", messageAdded);
			conversationsClient.on("tokenAboutToExpire", refreshToken);
			conversationsClient.on("tokenExpired", refreshToken);
			conversationEvents = true;
		} catch (err) {
			console.error("Error while adding conversation events ", err);
			throw err;
		}
	};

	const getEmailAuthType = async (email) => {
		if (email) {
			try {
				return await getAuthType(email);
			} catch (error) {
				console.error("Error while getting email auth type", error);
			}
		}
	};

	const conversationTokenAquired = async(jwt, userGroup) => {
		if (userGroup?.identity) {
			if (userGroup.email) {
				try {
					const authType = await getEmailAuthType(userGroup.email);

					userGroup["emailProvider"] = authType?.type;
					userGroup["valid"] =  authType?.valid;
				} catch (error) {
					console.error("Error while getting email token", error);
				}
			}

			store.commit("conversation/setCurrentParticipant", userGroup);
		}

		setClient(jwt);
	};

	const bootstrap = async (userGroupIdentity) => {
		try {
			toggleState(INITIALIZING);
			await cleanup();
			await authenticate(userGroupIdentity);
		} catch (err) {
			console.error("bootstrap:", err);
			toggleState(INITIALIZATION_FAILED);
		}
	};

	const setClient = async(jwt) => {
		try {
			await releaseClient();
			if (jwt) {
				conversationsClient = await ConversationsClient.create(jwt);
				conversationsClient.on("connectionStateChanged", connectionStateChanged);
				conversationsClient.on("connectionError", connectionError);
			}
		} catch (err) {
			toggleState(INITIALIZATION_FAILED);
			console.error("Conversations initialization error:", err);

			store.commit("alerts/add", {
				type: "error",
				message: i18n.t("error.twilio-client"),
				timeout: true
			});
		}
	};

	const cleanup = async () => {
		console.debug("[Conversation] cleanup");

		store.commit("queue/releaseCalls");
		store.commit("conversation/destroySingleConversation");
		lastVoiceEvents = {};
		releaseClient();
	};

	const releaseClient = async () =>{
		if (!conversationsClient) return;

		try {
			removeConversationEvents();

			conversationsClient.off("connectionStateChanged", connectionStateChanged);
			conversationsClient.off("connectionError", connectionError);
			await conversationsClient.shutdown();
			conversationsClient = null;
		} catch (err) {
			console.error("Error occured while cleaning up conversation objects", err);
			throw err;
		}
	};

	return {
		bootstrap,
		toggleState,
		cleanup
	};
};

export default useConversation;
