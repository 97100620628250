import axios from "axios";
import { updateField, getField } from "vuex-map-fields";

export default {
	getters: {
		getField,
		allGroups: (state) => state.allGroups,
		assignedGroups: (state) => state.assignedGroups
	},
	mutations: {
		updateField,
		resetGroups(state) {
			state.allGroups = null;
			state.assignedGroups = null;
		}
	},
	actions: {
		async getAssignedGroups( { state }, force = false) {
			if (state.assignedGroups && !force) {
				return [...state.assignedGroups];
			}

			var assigned = await axios.get("user-groups/belongs");

			state.assignedGroups = assigned.data.sort((a, b) =>{ return a.label.localeCompare(b.label); });

			return [...state.assignedGroups];
		},

		async getAllGroups({ state }, force = false) {
			if (state.allGroups && !force) {
				return state.allGroups;
			}

			var all = await axios.get("user-groups");

			state.allGroups = all.data.sort((a, b) =>{ return a.label.localeCompare(b.label); });
			return state.allGroups;
		}
	},
	namespaced: true,
	state: () => ({
		allGroups: null,
		assignedGroups: null
	})
};
